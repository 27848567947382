
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    redirect: '/index',
  },
    //首页
  {
    path: '/index',
    name:'首页',
    component: () => import('@/views/Home/index')
  },
    //关于我们
  {
    path: '/about',
    name:'关于我们',
    component: () => import('@/views/About/index'),
  },
  {
    path: '/CompanyProfile',
    name:'公司简介',
    component: () => import('@/views/About/index'),
  },
  //产品体系
  {
    path: '/products',
    name:'产品体系',
    component: () => import('@/views/Products/index')
  },
  {
    path: '/SchedulingManagement',
    name:'调度管理与辅助决策',
    component: () => import('@/views/Products/index')
  },
  //新闻动态
  {
    path: '/news',
    name:'新闻动态',
    component: () => import('@/views/News/index')
  },
  {
    path: '/whConsulting',
    name:'网华咨询',
    component: () => import('@/views/News/index')
  },
  //联系我们
  {
    path: '/contact',
    name:'联系我们',
    component: () => import('@/views/Contact/index')
  },
  {
    path: '/tRecruitment',
    name:'人才招聘',
    component: () => import('@/views/Contact/tRecruitment')
  },
  {
    path: '/contentdata',
    name:'岗位信息',
    component: () => import('@/views/Contact/contentdata')
  },
]
let originPush =  VueRouter.prototype.push;  //备份原push方法
 
VueRouter.prototype.push = function (location, resolve, reject){
    if (resolve && reject) {    //如果传了回调函数，直接使用
        originPush.call(this, location, resolve, reject);
    }else {                     //如果没有传回调函数，手动添加
        originPush.call(this, location, ()=>{}, ()=>{}); 
    }
}
const router = new VueRouter({
  routes,
  base: process.env.BASE_URL,
})

export default router
